import {
  REGISTER_USER,
  LOGIN_USER,
  LOGOUT_USER,
  TOGGLE_AUTH_USER,
  USER_DETAILS,
} from '../types/user';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  userDetails:{}
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_USER:
      return { ...state, user: payload };
    case LOGIN_USER:
      const logun_user = JSON.stringify(payload);
      localStorage.setItem('user', logun_user);
      return { ...state, user: payload };
    case LOGOUT_USER:
      localStorage.removeItem('user');
      return { ...state, user: payload };
    case USER_DETAILS:
      return { ...state, userDetails: payload };
    case TOGGLE_AUTH_USER:
      return { ...state, toggleAuthUser: !state.toggleAuthUser };
    default:
      return state;
  }
};

export default userReducer;
