import React, { useEffect, useRef, useState } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import './containers/css/dashbord.css';
import './containers/css/circleRadius.css';
import Awards from './Awards';
import configKeys from "../config/keys"
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import DashbordYTD from './containers/DashbordYTD';
import DashbordQTD from './containers/DashbordQTD';


const PreviewPdf = (props) => {
  const user = props.user
  const params = useParams()
  const location  = useLocation()
  const curMonthYear = new URLSearchParams(location.search).get("curMonthYear")
  const startMonthYear = new URLSearchParams(location.search).get("startMonthYear")
  // const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const [Loading, setLoading] = useState({
    loading: false,
    success: '',
    error: '',
  });
  const [dashbordDetails, setdashbordDetails] = useState('');
  const [dashbordDetailsByQuarter, setdashbordDetailsByQuarter] = useState('');
  const [pdfName, setPdfName] = useState('Report');

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  // const exportPDFWithMethod = () => {
  //     let element = container.current || document.body;
  //     savePDF(element, {
  //       paperSize: "auto",
  //       margin: 40,
  //       fileName: `Report for ${new Date().getFullYear()}`
  //     });
  //   };
  useEffect(() => {
    getDasbordDetails()
    getDasbordDetailsByQuarter()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const getDasbordDetails = async () => {
    try {

      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      let dashbordData = {
        funderId: params.id,
        curMonthYear: curMonthYear,
        startMonthYear: startMonthYear,
      };
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v3/dashboardFunder`,{...dashbordData},{headers: {"Authorization": user?.token}});
      setdashbordDetails(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };

  const getDasbordDetailsByQuarter = async () => {
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      let dashbordData = {
        funderId: params.id,
        curMonthYear: curMonthYear,
        startMonthYear: startMonthYear,
      };
      const { data } = await axios.post(`${configKeys.SERVER_URL}/v3/dashboardFunderByQuarter`,{...dashbordData},{headers: {"Authorization": user?.token}});
      setdashbordDetailsByQuarter(data.data);
      let pdfReportName = data.data.program+ " " +data.data.monthNames
      setPdfName(pdfReportName)
      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };

  return (
    <>
      {/* PDF Button */}
      <button
        // style={{"marginTop"}}

        ref={pdfExportComponent}
        onClick={exportPDFWithComponent}
        className="btn btn-outline-success px-2 mt-3 "
        //   document={<PdfDocument data={movieDetails} />}
        fileName="movielist.pdf"
      >
        PDF⬇️
      </button>
      <PDFExport
        paperSize="A4"
        keepTogether='div'
        scale={0.45}
        margin={'0cm'}
        ref={pdfExportComponent}
        forcePageBreak=".page-break"
        fileName={`${pdfName}`}
        author="SamparkFoundation"
      >
        <section  >
        <div className='container' style={{padding:'0px'}}>
          <div className="col-12"  >
          <nav className="navbar_container_pdf"  style={{
            backgroundImage: 'url(/assets/dashbord/funder-dashboard-header-bg.png)',
            backgroundSize: 'contain'
          }}>
          <img src="/assets/dashbord/funder-dashboard-logo-with-bg.png" style={{height:'175px',width:'auto'}} alt="" />
            <div className="d-flex justify-content-end w-100 " style={{alignItems: 'center'}}>
              <div className="section_1_year" style={{color:'#ffffff', textAlign:'right'}}>
                <div>Sampark Smart Shala Program</div>
                <div>{!Loading.loading && dashbordDetailsByQuarter ? dashbordDetailsByQuarter.ytdname : ''}</div>
              </div>
            </div>
          </nav>
        </div>
        </div>
        <div className='container' >
                {/* part 1 */}
          <div className="col-12 mb-5"  >
            <div className='section_1'>
              <div className='d-flex justify-content-between flex-column'>
                <div className='section_1_head'>
                  Program Investment
                </div>
              </div>

              <div className='' >
                <div className='d-flex justify-content-end'>   
                  <div className='section_1_head'>
                    
                  </div>
                </div>
              </div>
            </div>
            {!Loading.loading && dashbordDetails ? (
            <DashbordYTD dashbordDetailsByQuarter={dashbordDetailsByQuarter} dashbordDetails={dashbordDetails} funderType={"Science"} />
            ) : (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            )}
          </div>
        </div>
        </section>
      
        <section className='page-break' >
        <div className='container' style={{padding:'0px'}}>
          <div className="col-12"  >
            <nav className="navbar_container_pdf"  style={{
              backgroundImage: 'url(/assets/dashbord/funder-dashboard-header-bg.png)',
              backgroundSize: 'contain'
            }}>
            <img src="/assets/dashbord/funder-dashboard-logo-with-bg.png" style={{height:'175px',width:'auto'}} alt="" />
              <div className="d-flex justify-content-end w-100 " style={{alignItems: 'center'}}>
                <div className="section_1_year" style={{color:'#ffffff', textAlign:'right'}}>
                  Sampark Smart Shala Program<br></br>{!Loading.loading && dashbordDetailsByQuarter ? dashbordDetailsByQuarter.monthNames : ''}
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="container">
          {/* part 2 */}
          <div className="col-12 mb-5 ">
            {/* Program Investment */}
            <div className='section_1'>
            <div>
              <div className='section_1_head'>
                Program Investment
              </div>
              </div>
              <div className='section_1_head'>
                
              </div>
            </div>
            {!Loading.loading && dashbordDetailsByQuarter ? (
            <DashbordQTD dashbordDetailsByQuarter={dashbordDetailsByQuarter} dashbordDetails={dashbordDetails} funderType={"Science"} />
            ) : (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            )}
          </div>
        </div>
        </section>
        <section className='page-break' >
        <div className='container' style={{padding:'0px'}}>
          <div className="col-12"  >
            <nav className="navbar_container_pdf"  style={{
              backgroundImage: 'url(/assets/dashbord/funder-dashboard-header-bg.png)',
              backgroundSize: 'contain'
            }}>
            <img src="/assets/dashbord/funder-dashboard-logo-with-bg.png" style={{height:'175px',width:'auto'}} alt="" />
              <div className="d-flex justify-content-end w-100 " style={{alignItems: 'center'}}>
                <div className="section_1_year" style={{color:'#ffffff', textAlign:'right'}}>
                  Sampark Smart Shala Program
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className=''>
          <Awards name={'School'} showHeader={true}  />
        </div>
        </section>
        <section className='page-break' >
        <div className='container' style={{padding:'0px'}}>
          <div className="col-12"  >
            <nav className="navbar_container_pdf"  style={{
              backgroundImage: 'url(/assets/dashbord/funder-dashboard-header-bg.png)',
              backgroundSize: 'contain'
            }}>
            <img src="/assets/dashbord/funder-dashboard-logo-with-bg.png" style={{height:'175px',width:'auto'}} alt="" />
              <div className="d-flex justify-content-end w-100 " style={{alignItems: 'center'}}>
                <div className="section_1_year" style={{color:'#ffffff', textAlign:'right'}}>
                  Sampark Smart Shala Program
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div >
          <Awards name={'Teacher'} showHeader={true}  />
        </div>
        </section>

      </PDFExport>
    </>
  );
};

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,
  };
};


export default connect(mapStateStore,{})(PreviewPdf);
