import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import './css/schoolImpacted.css';

const FunderEdit = (props) => {
    const params = useParams();
    const user = props.user;
    // const userDetails = props.userDetails;
    const mHistory = useNavigate();
    const [funderData, setFunderData] = useState('')
    const [funderLoading, setFunderLoading] = useState({
      loading: false,
      success: '',
      error: '',
    });
    const token = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
      if (!user || user === null) {
        console.log("true user")
        return mHistory('/login');
      }
      if (!token) return mHistory('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
      getFunderById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFunderById = async ()=>{
      try{
        setFunderLoading({
          loading: true,
          success: '',
          error: '',
        });
        const {data} = await axios.get(`${configKeys.SERVER_URL}/v3/getFunderByID/${params.id}`)
        console.log("STATES : ",data.data.states)
        setFunderData(data.data)
        setFunderLoading({
          loading: false,
          success: '',
          error: '',
        });
      }catch(error){
        setFunderLoading({
          loading: false,
          success: '',
          error: error?.response?.data?.message,
        });
      }
    }

    return (
      <div className="container">
        {
          funderLoading.loading && funderData ? (
              <div class="spinner-border text-primary " role="status">
                <span class="sr-only"></span>
              </div>
          ) : (
        <div>
          <div className='d-flex flex-row' >
            <div className={'_input_container flex_per_row_for_filters col-lg-6 mt-5 mb-4 schoolHead text-left'} style={{textAlign:"left"}}>Program Details</div>
            <div className={'_input_container flex_per_row_for_filters col-lg-6 mt-5 mb-4 schoolHead text-left'} style={{textAlign:"right"}}><Link className='btn btn-secondary' style={{textAlign:'right'}}  to="/funderGroupList">Programs</Link></div>
          </div>
          <div className='d-flex flex-row' >
            <div className={'_input_container flex_per_row_for_filters col-lg-6 text-left'} style={{textAlign:"left",color:funderData.isEnabled ? "green" : "red"}}>{funderData.isEnabled ? 'Enabled for Dashboard' : 'Disabled for Dashboard'}</div>
            <div className={'_input_container flex_per_row_for_filters col-lg-6 text-left'} style={{textAlign:"right",color:funderData.isActive ? "green" : "red"}}>{funderData.isActive ? 'Program is Currently Active' : 'Program is Currently In-Active'}</div>
          </div>
          <div className="d-flex flex-row">
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Partner Name : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-4 text-left'} style={{textAlign:"left"}}>
              {funderData.partnerName}
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Program Code : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-4 text-left'} style={{textAlign:"left"}}>
              {funderData.programCode}              
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Program Type : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-4 text-left'} style={{textAlign:"left"}}>
              {funderData.programType}
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Program Duration : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-4 text-left'} style={{textAlign:"left"}}>
              {funderData.programStartMonth}-{funderData.programStartYear} TO {funderData.programEndMonth}-{funderData.programEndYear}
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>State : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-4 text-left'} style={{textAlign:"left"}}>
              {funderData.states?.map((item) => (
                item.label
              ))}
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Districts : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-4 text-left'} style={{textAlign:"justify"}}>
              {funderData.districts?.map((item) => (
                item.label+", "
              ))}
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Blocks : </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-10 text-left'} style={{textAlign:"justify"}}>
              {funderData.blocks?.map((item) => (
                item.label+", "
              ))}
            </div>
          </div>
          {funderData.schools && funderData.schools.length > 0 && 
          <div className="d-flex flex-row">
            <div className={'_input_container flex_per_row_for_filters col-lg-2 text-left'} style={{textAlign:"left"}}>
              <label className={'_label'}>Schools ({funderData.schools.length}): </label>
            </div>
            <div className={'_input_container flex_per_row_for_filters col-lg-10 text-left'} style={{textAlign:"justify"}}>
              {funderData.schools?.map((item) => (
                <div className="d-flex flex-row">
                  <div className={'col-lg-12 text-left'} style={{textAlign:"left"}}>
                    {item.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
          }
        </div>
          )
        }
      </div>
  );
}

const mapStateStore = (stateStore) => {
    return {
      user: stateStore.userState.user,
    };
  };

export default  connect(mapStateStore,{})(FunderEdit)
