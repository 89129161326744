import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
import {  Link, useNavigate } from 'react-router-dom';
import { isInputEmpty,isInputHasLength } from '../constants/inputValidaation';
import { connect } from 'react-redux';

import { components } from "react-select";
import Select from 'react-select';
import './css/schoolImpacted.css';

const FunderGroup = (props) => {
    const user = props.user;
    const userDetails = props.userData;
    const mHistory = useNavigate();
    const [selectedStates, setselectedStates] = useState('')
    const [selectedDistricts, setselectedDistricts] = useState('')
    const [selectedBlocks, setselectedBlocks] = useState('')
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [blocks, setBlocks] = useState([])
    const [formData, setFormData] = useState({
        funderName: '',
        stateList: '',
        districtList: '',
        blockList:'',
        fln:true,
        science: true
      });
      const [formDataError, setFormDataError] = useState({
        funderName: '',
        stateList: '',
        districtList: '',
        blockList:'',
        fln:true,
        science: true
      });
      const [requestAuth, setRequestAuth] = useState({
        success: '',
        error: '',
        loading: false,
      });
    

      const token = JSON.parse(localStorage.getItem('user'));
      useEffect(() => {
        if (!user || user === null) {
          return mHistory('/login');
        }
        if (!userDetails || userDetails.admintype !== "superadmin") {
          return mHistory('/login');
        }
        if (!token) return mHistory('/login');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [user]);

      useEffect(() => {
        getStates()
      }, [])
      
      useEffect(() => {
        if(selectedStates){
          getDistricts()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedStates])
    
      useEffect(() => {
        if(selectedStates && selectedDistricts.length > 0){
          setselectedBlocks('')
          getBlocks()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedDistricts])

  const getStates = async ()=>{
    try{
      const {data} = await axios.get(`${configKeys.SERVER_URL}/states`)
      setStates(data.data)
    }catch(error){
      console.log(error.message)
    }
  }

  const getDistricts = async ()=>{
    try{
      const {data} = await axios.post(`${configKeys.SERVER_URL}/districts`,{states:selectedStates})
      setDistricts(data.data)
      setselectedDistricts(data.data)
    }catch(error){
      console.log(error.message)
    }
  }
  const getBlocks = async ()=>{
    try{
      const {data} = await axios.post(`${configKeys.SERVER_URL}/blocks`,{states:selectedStates,districts:selectedDistricts})
      setBlocks(data.data)
      setselectedBlocks(data.data)
    }catch(error){
      console.log(error.message)
    }
  }

  const  handleChange = (selected) => {
    setselectedStates(selected)
  };
  const  handleChangeDistrict = (selected) => {
    setselectedDistricts(selected)
  };
  const  handleChangeBlock = (selected) => {
    setselectedBlocks(selected)
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const validateForm = () => {
    let hasError = false;

    let tempError = {
      funderName: '',
      stateList: '',
      districtList: '',
      blockList:''
    };

    tempError.stateList = isInputHasLength(selectedStates);
    if (tempError.stateList !== '') {
      hasError = true;
    }
    tempError.funderName = isInputEmpty(formData.funderName);
    if (tempError.funderName !== '') {
      hasError = true;
    }
    tempError.districtList = isInputHasLength(selectedDistricts);
    if (tempError.districtList !== '') {
      hasError = true;
    }
    tempError.blockList = isInputHasLength(selectedBlocks);
    if (tempError.blockList !== '') {
      hasError = true;
    }

    setFormDataError({
      ...formDataError,
      ...tempError,
    });

    return hasError;
  };


  const onSubmit = async () => {
    if (validateForm()) {
      return;
    }

    try {
      setRequestAuth({
        loading: true,
        error: '',
        success: '',
      });
      let funderData = {}
      funderData['funderName'] = formData.funderName
      funderData['blockList'] = selectedBlocks
      funderData['districtList'] = selectedDistricts
      funderData['stateList'] = selectedStates
      funderData['fln'] = formData.fln
      funderData['science'] = formData.science
      await axios.post(`${configKeys.SERVER_URL}/v3/funderGroup`,{...funderData})
      setRequestAuth({
        loading: false,
        error: '',
        success: 'Funder Created Successfully',
      });
      setTimeout(() => {
        setFormData({
          name: '',
          stateList: '',
          districtList: '',
          blockList:''
        })
        setRequestAuth({
          success: '',
          error: '',
          loading: false,
        })
        setselectedStates('')
        setselectedDistricts('')
        setselectedBlocks('')
      }, 3000);

    } catch (error) {
      console.log(error);
      setRequestAuth({
        loading: false,
        error: error?.response?.data?.message,
        success: '',
      });
    }
  };

    return (
        <div className="container">
          <div className='d-flex justify-content-between'>
          <div className="mt-5 mb-4 schoolHead ">Create Program</div>
          <Link  className='mt-5 mb-5 btn btn-secondary'  to="/funderGroupList">Programs List</Link>
          </div>
          <div className="d-flex flex-wrap flex-column align-items-center">
            <div className={'_input_container flex_per_row_for_filters mb-4 col-lg-4 text-left'} style={{textAlign:"left"}} >
              <label className={'_label'}>Program Name</label>
              <input className='form-control'  name='funder' value={formData.funderName} placeholder='Enter Program Name'   onChange={(t) => {setFormData({...formData,funderName: t.target.value})}}  />
              {formDataError.funderName && (
                <span className="text-danger">{formDataError.funderName}</span>
              )}
            </div>
                 <div className="mb-4 col-lg-4 text-left radio_container">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="admin"
                id="admin"
                checked={formData.fln === true ? true : false }
                value={formData.fln === true ? true : false}
                onClick={e=>{
                  e.target.checked ? setFormData({...formData,fln:true}) : setFormData({...formData,fln:false}) 
                }}
              />
              <label class="form-check-label" for="flexRadioDefault1">
              FLN 
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="standard_user"
                id="standard_user"
                checked={formData.science === true ? true : false }
                value={formData.science === true ? true : false}
                onClick={e=>{
                   e.target.checked ? setFormData({...formData,science:true}) : setFormData({...formData,science:false}) 
                }}
              />
              <label class="form-check-label" for="flexRadioDefault2">
              Science
              </label>
            </div>
          </div>
            <div className={'mb-4 col-lg-4 text-left'}  style={{textAlign:"left"}}>
                <label className={'_label'}>Select state</label>
                <Select
                  components={{Option}}
                  value={selectedStates}
                  name="states"
                  options={states}
                  // className="basic_multi_select"
                  // classNamePrefix="select"
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleChange}
                />
                {formDataError.stateList && (
                  <span className="text-danger">{formDataError.stateList}</span>
                )}
              </div>
              <div className={'mb-4 col-lg-4'}  style={{textAlign:"left"}}>
                <label className={'_label'}>Select district</label>
                <Select
                  isMulti
                  components={{Option}}
                  value={selectedDistricts}
                  name="district"
                  options={districts}
                  // className="basic_multi_select"
                  // classNamePrefix="select"
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleChangeDistrict}
                />
                {formDataError.districtList && (
                  <span className="text-danger">{formDataError.districtList}</span>
                )}
              </div>
              <div className={'mb-4 col-lg-4'}  style={{textAlign:"left"}}>
                <label className={'_label'}>Select blocks</label>
                <Select
                  isMulti
                  components={{Option}}
                  value={selectedBlocks}
                  name="blocks"
                  options={blocks}
                  // className="basic_multi_select"
                  // classNamePrefix="select"
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleChangeBlock}
                />
                {formDataError.blockList && (
                  <span className="text-danger">{formDataError.blockList}</span>
                )}
              </div>

            </div>
       
         
          {requestAuth.loading && (
                <div className="rounded mt-1 mb-1 p-1 border border-info text-center text-info">
                  Loading...
                </div>
              )}
              {requestAuth.success && (
                <div className="rounded mt-1 mb-1 p-1 border border-success text-center text-success">
                  {requestAuth.success}
                </div>
              )}
              {requestAuth.error && (
                <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
                  {requestAuth.error}
                </div>
              )}
          <div className="text-left">
            <button className="mt-5 btn btn-success py-3  btnSchool" onClick={() => onSubmit()}>
              Submit
            </button>
          </div>
        </div>
      );
}

const mapStateStore = (stateStore) => {
    return {
      user: stateStore.userState.user,
      userData: stateStore.userState.userDetails,

    };
  };

export default connect(mapStateStore,{})(FunderGroup)

