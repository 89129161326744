import axios from "axios"
import {REGISTER_USER,LOGIN_USER,LOGOUT_USER,TOGGLE_AUTH_USER,USER_DETAILS} from "../types/user"
import {SERVER_URL} from "../../config/keys"


export const log_in =(email,password) => async Dispatch =>{
    try{
        Dispatch({type:TOGGLE_AUTH_USER})
        const {data} = await axios.post(SERVER_URL+"/login",{email,password})
        Dispatch({type:LOGIN_USER,payload:data.data})
        return data
    }catch(err){
        if (err.response && err.response.data) {
            Dispatch({type:TOGGLE_AUTH_USER})
            console.log(err)
          }
          throw err
          
    }finally{
        Dispatch({type:TOGGLE_AUTH_USER})
    }
}

export const register_user =(name,email,password,admintype,token,funderId) => async Dispatch =>{
    try{
        Dispatch({type:TOGGLE_AUTH_USER})
        const {data} = await axios.post(SERVER_URL+"/register",{name,email,password,admintype,funderId}, {headers: {"Authorization": token.token}})
        Dispatch({type:REGISTER_USER,payload:data.data})
        return data
    }catch(err){
        if (err.response && err.response.data) {
            Dispatch({type:TOGGLE_AUTH_USER})
            console.log(err)
          }
          throw err
    }finally{
        Dispatch({type:TOGGLE_AUTH_USER})
    }
}

export const user_details =(token) => async Dispatch =>{
    try{
        Dispatch({type:TOGGLE_AUTH_USER})
        const {data} = await axios(`${SERVER_URL}/userDetails?token=${token}`)
        Dispatch({type:USER_DETAILS,payload:data.data})
        return data
    }catch(err){
        if (err.response && err.response.data) {
            Dispatch({type:TOGGLE_AUTH_USER})
            console.log(err)
          }
          throw err
    }finally{
        Dispatch({type:TOGGLE_AUTH_USER})
    }
}

export const log_out =(token) => async Dispatch =>{
    // const user1 = JSON.parse(localStorage.getItem("user"))
    try{
        console.log("log_out")
        Dispatch({type:TOGGLE_AUTH_USER})
        // const {data} = await axios.delete(SERVER_URL+"/logout",{headers: {"Authorization": token}})
        // console.log(data.data)
        Dispatch({type:LOGOUT_USER,payload:null})
        return 'l'
    }catch(err){
        if (err.response && err.response.data) {
            Dispatch({type:LOGOUT_USER,payload:null}) // some reason error message
            console.log(err)
          }
          throw err
    }finally{
        Dispatch({type:TOGGLE_AUTH_USER})
    }
}

