import React from 'react'
import TopAward from './containers/TopAward'

const Awards = ({name, showHeader}) => {
  return (
    <div>
      <TopAward name={name} showHeader={showHeader}  />
    </div>
  )
}

export default Awards
