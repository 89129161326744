import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import configKeys from '../config/keys';
// import inputValidation from '../constants/inputValidaation';
import { connect } from 'react-redux';

import './css/schoolImpacted.css';

const UserList = ({userData}) => {
  // const userDetails = props.userData
  // const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [Loading, setLoading] = useState({
      loading: false,
      success: '',
      error: '',
    });
    
    // useEffect(() => {
    //   console.log(userData)
    //   if(userData?.admintype !== "superadmin"){
    //     return navigate('/');
    //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    useEffect(() => {
      getSchoolList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const getSchoolList = async () => {

      try {
        setLoading({
          loading: true,
          success: '',
          error: '',
        });
        const { data } = await axios(`${configKeys.SERVER_URL}/getallUsers`);
        setUserList(data.data);
  
        setLoading({
          loading: false,
          success: '',
          error: '',
        });
      } catch (error) {
        setLoading({
          loading: false,
          success: '',
          error: error.message,
        });
      }
    };
  
    
    return (
        <div className="container">
        <div className='d-flex justify-content-between' >
        <div className="mt-5 mb-4 schoolHead">User Created By Admin</div>
  
        <Link  className='mt-5 mb-4 '  to="/register">Create New User</Link>
        </div>
        <table className="withBorder">
          <tr className='table_head'>
            <th>Full Name</th>
            <th>Email</th>
            <th>Admine Type</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
          {userList?.map((item) => (
            <tr>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.admintype}</td>
              <td>{item.createdBy}</td>
              <td>{item.createdAt}</td>
              <td><Link to={`/userEdit/${item.token}`} >Edit</Link></td>
            </tr>
          ))}
        </table>
          {userList?.length === 0 && <dir>Empty School List</dir>}
        
          {Loading.loading && <h1>Loading..... </h1>}
      </div>
    );
}

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,
  };
};

export default connect(mapStateStore)(UserList)
