import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import configKeys from '../config/keys';
import inputValidation from '../constants/inputValidaation';

import './css/schoolImpacted.css';

const StaticDataList = (props) => {
  const params = useParams();
  const userDetails = props.userData
  
  const [schoolList, setschoolList] = useState([]);
  const [funderList, setFunderList] = useState([]);
  const [Loading, setLoading] = useState({
    loading: false,
    success: '',
    error: '',
  });

  const months = {"0":"Yearly", "1":"1st Quarter", "2":"2nd Quarter", "3":"3rd Quarter", "4":"4th Quarter"};

  const [formData, setformData] = useState({
    year: '2023',
    month: '',
    funderId:params.id,
  });
  const [fromDataError, setFromDataError] = useState({
    year: '',
    month: '',
    funderId:'',
  });

  useEffect(() => {
    getStaticDataByFunder();
    getFunderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.funderId]);

  const validateForm = () => {
    let hasError = false;

    let tempError = {
      year: '',
      month: '',
      funderId: '',
      state_name: '',
      district_id: '',
      district_name: '',
      count: '',
    };

    tempError.funderId = inputValidation.isInputEmpty(formData.funderId);
    if (tempError.funderId !== '') {
      hasError = true;
    }
    // tempError.district_id = inputValidation.isInputEmpty(formData.district_id);
    // if (tempError.district_id !== '') {
    //   hasError = true;
    // }

    tempError.year = inputValidation.isInputEmpty(formData.year);
    if (tempError.year !== '') {
      hasError = true;
    }


    setFromDataError({
      ...fromDataError,
      ...tempError,
    });

    return hasError;
  };

  const getStaticDataByFunder = async () => {
    if (validateForm()) {
      return;
    }
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });

      const { data } = await axios(`${configKeys.SERVER_URL}/StaticDataByFunder?year=${formData.year}&funderId=${formData.funderId}`);
      setschoolList(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };

  const getFunderList = async () => {
  
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      const { data } = await axios(`${configKeys.SERVER_URL}/v3/funderGroupList?label=true`);
      setFunderList(data.data);

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };
  return (
    <div className="m-4">
        <div className='d-flex justify-content-between' >
          <div className="mt-5 mb-4 schoolHead">Program Wise Static Data List</div>

          <Link  className='mt-5 mb-4 '  to="/staticDataByFunder">Create Static Data</Link>
        </div>
      
        <div className="d-flex">
          <div className={'_input_container'}>
            <label className={'_label'}>Select Program</label>
            <select
              className={'_input'}
              style={{ cursor: 'pointer' }}
              id="year"
              name="year"
              value={formData.funderId}
              onChange={(t) => {
                setformData({
                  ...formData,
                  funderId: t.target.value,
                });
              }}
            >
              <option value="" selected>
                Select
              </option>
              {funderList?.map((item) => (
                <option id={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {fromDataError.funderId && (
              <span className="text-danger">{fromDataError.funderId}</span>
            )}
          </div>
        </div>
        <table className="withBorder">
          <tr>
            <th>Quarter</th>
            <th>Yearly Budget</th>
            <th>Program Expenses (YTD)</th>
            <th>Program Expenses (QTD)</th>
            <th>Total Schools in State</th>
            <th>No. Of Schools Covered</th>
            <th>Total Students in State </th>
            <th>No. Of Students Covered</th>
            <th>No. Of Sparks</th>
            <th>Kits Distributed</th>
            <th>Sampark TV/LED Distributed</th>
            <th>Teachers Trained</th>
            <th>MT Trained</th>
            <th>Created By</th>
            <th>Updated By</th>
            <th>Action</th>
          </tr>
          {schoolList?.map((item) => (
            <tr>
              <td>{months[item.quarter]}</td>
              <td>{item.yearly_budget}</td>
              <td>{item.program_expenses_ytd}</td>
              <td>{item.program_expenses_qtd}</td>
              <td>{item.total_schools_in_state}</td>
              <td>{item.schools_covered}</td>
              <td>{item.total_students_in_state}</td>
              <td>{item.students_covered}</td>
              <td>{item.spark_count}</td>
              <td>{item.kits_distributed}</td>
              <td>{(item.stv_distributed+"/"+item.led_distributed)}</td>
              <td>{item.teachers_trained}</td>
              <td>{item.mt_trained}</td>
              <td>{item.createdBy}</td>
              <td>{item.updatedBy}</td>
              <td>
                {((item.freezed === false) && (item.createdBy === userDetails.email || userDetails.admintype === 'superadmin') && <Link to={`/editStaticDataByFunder/${item._id}`} >Edit</Link>)}
                {((item.freezed === true) && 'Data Freezed')}
              </td>
            </tr>
          ))}
        </table>
        {schoolList?.length === 0 && <dir>Empty List</dir>}
      
        {Loading.loading && <h1>Loading..... </h1>}
    </div>
  );
}

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,
  };
};

export default connect(mapStateStore,{})(StaticDataList)
