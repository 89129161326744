import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { isInputEmailValid } from '../constants/inputValidaation';
import { log_in } from '../redux/actions/userActions';
import './css/login.css';

const Login = (props) => {
  const log_in = props.log_in;
  const mHistory = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [requestAuth, setRequestAuth] = useState({
    success: '',
    error: '',
    loading: false,
  });

  const [formDataError, setFormDataError] = useState({
    email: '',
    password: '',
  });

  const token = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (token) return mHistory('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const validateForm = () => {
    let hasError = false;

    let tempError = {
      email: '',
      password: '',
    };

    tempError.email = isInputEmailValid(formData.email);
    if (tempError.email !== '') {
      hasError = true;
    }

    setFormDataError({
      ...formDataError,
      ...tempError,
    });

    return hasError;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      return;
    }

    try {
      setRequestAuth({
        loading: true,
        error: '',
        success: '',
      });

      await log_in(formData.email, formData.password);
      setRequestAuth({
        loading: false,
        error: '',
        success: 'Login Successfully',
      });

      mHistory('/');
    } catch (error) {
      console.log(error);
      setRequestAuth({
        loading: false,
        error: error?.response?.data?.message,
        success: '',
      });
    }
  };

  return (
    <div className="text-center">
      <div className="login_container">
        <div className="login_head"><span>
          <img src="/assets/dashbord/loginLogo.svg" alt="logo" /></span> </div>
        <form className="text-center form_container">
          <div className="form-group text-left" style={{ textAlign: 'left' }}>
            {/* <label>Enter Email ID</label> */}
            <input
              type="email"
              name="Email"
              placeholder="Enter your Email"
              className="form-control p-2  EmailInput"
              value={formData.email}
              onChange={(t) => {
                setFormData({
                  ...formData,
                  email: t.target.value,
                });
              }}
            />
            {formDataError.email && (
              <span className="text-danger">{formDataError.email}</span>
            )}
          </div>
          <div className={`Container`}>
            <div>
              <input
                type={showPassword ? 'text' : 'password'}
                name={'password'}
                id={'password'}
                className="Input"
                value={formData.password}
                placeholder={'Please enter your password'}
                onChange={(e) => {
                  // setShowBtn(true);
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  });
                }}
              />
              <img
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                src={
                  showPassword
                    ? '/assets/icons/iconPasswordShow.svg'
                    : '/assets/icons/iconPasswordHide.svg'
                }
                alt={showPassword ? 'Show Password' : 'Hide Password'}
              />
            </div>
          </div>
          {(formDataError.email || formDataError.password) && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              Invalid password or Email
            </div>
          )}

          {requestAuth.loading && (
            <div className="rounded mt-1 mb-1 p-1 border border-info text-center text-info">
              Loading...
            </div>
          )}
          {requestAuth.success && (
            <div className="rounded mt-1 mb-1 p-1 border border-success text-center text-success">
              {requestAuth.success}
            </div>
          )}
          {requestAuth.error && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              {requestAuth.error}
            </div>
          )}

          <div className={'__btnSubmit_container'}>
            {formData.password !== '' &&
            formData.email !== ''  ? (
              <button
                className={`login_sectionSubmit_button`}
                type="button"
                onClick={() => onSubmit()}
              >
                Login
              </button>
            ) : (
              <button
                className={`login_sectionSubmit_disabledButton`}
                disabled
                type="button"
                onClick={() => onSubmit()}
              >
                Login
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,
  };
};

export default connect(mapStateStore, { log_in })(Login);
