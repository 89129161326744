// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { log_out,user_details } from '../redux/actions/userActions';
// import configKeys from '../config/keys';
import './css/navbar.css';
import { OverlayTrigger } from 'react-bootstrap';
// import { Popover } from 'bootstrap';

const Navbar = (props) => {
  const History = useNavigate();
  const user = props.user;
  const userData = props.userData;
  const user_details = props.user_details;
  const log_out = props.log_out;
  const [showPopover, setshowPopover] = useState(false);
  
  useEffect(() => {
    if (user?.token) {
      userDetails(user?.token);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const userDetails = async (token) => {
    try {
      await user_details(token)
    } catch (error) {
      console.log(error.message);
      logout();
    }
  };

  const logout = (e) => {
    // e.preventDefault();
    log_out();
    History('/login');
  };
  const popoverCard = () => {
    return (
      <div className="model modelSubmenu" style={{ background: '#297CC1' }}>
        <div className="model_head">
        <Link to="/" className="createUser">
            Home Page
            </Link>
          {(userData.admintype === 'superadmin' || userData.admintype === 'program_manager') ? (
            <Link to="/funderGroupList" className="createUser">
              Programs
            </Link>
          ):null}
          {userData.admintype === 'superadmin' ? (
            <Link to="/register" className="createUser">
              Create New Users
            </Link>
          ):null}
          {(userData.admintype === 'superadmin' || userData.admintype === 'program_manager') ?  (
            <Link to="/StaticDataByFunder" className="createUser">
             Create Static Data
            </Link>
          ) : null}
          {userData.admintype === 'superadmin' ? (
            <Link to="/userList" className="createUser">
             User List
            </Link>
          ):null}
          {/* {userDetails.admintype === 'superadmin' && (
            <Link to="/childrenImpacted/list" className="createUser">
             Children Impacted
            </Link>
          )} */}
          {/* {userDetails.admintype === 'superadmin' && (
            <Link to="/sssKitDistributed/list" className="createUser">
             SSS Kits Distributed 
            </Link>
          )} */}
          {user && (
            <button className="logOutBtn" onClick={logout}>
              Logout
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>  
      </div>
      <nav className="navbar_container"  style={{
        backgroundImage: 'url(/assets/dashbord/funder-dashboard-header-bg.png)',
        backgroundSize: 'contain'
      }}>
      <img src="/assets/dashbord/funder-dashboard-logo-with-bg.png" alt="" />
        <div className="d-flex justify-content-end w-100 ">
          {/* <div className="btnMargin">
            <Link
              to="/"
              className="linkBtn"
              style={{ border: 'none', fontSize: '24px' }}
            >
              Sampark Smart Shala Program
            </Link>
          </div> */}
          {/* {!user && (
            <Link to="/login" className="login_btn">
              Login
            </Link>
          )} */}
          {user && (
            <div
              style={{ margin: 'auto 35px auto ' }}
              onClick={() => setshowPopover(!showPopover)}
            >
            <div className="section_1_year" style={{color:'#ffffff'}}>Sampark Smart Shala Program</div>
              <div className="d-flex align-items-center" style={{justifyContent: 'flex-end'}}>
                {/* <div className="navImg">
                  {UserDetails?.name && UserDetails?.name[0]?.toUpperCase()}
                </div> */}
                <div className="nav_name">{userData?.name}</div>
                <div className="navIcon">
                  <OverlayTrigger
                    show={showPopover}
                    placement="bottom-end"
                    overlay={popoverCard()}
                    trigger="click"
                   rootClose
                  >
                    <>
                      {showPopover ? (
                        <img src="/assets/icons/nav_down_arrow.svg" alt="" />
                      ) : (
                        <img src="/assets/icons/nav_up_arrow.svg" alt="" />
                      )}
                    </>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

const mapStateStore = (stateStore) => {
  return {
    user: stateStore.userState.user,
    userData: stateStore.userState.userDetails,
  };
};

export default connect(mapStateStore, { log_out ,user_details})(Navbar);
