import React from 'react'
import "../css/capsule.css"

const capsules = ({name , value}) => {

  return (
    <div className='capsule_container' >
    <div className='capsule_head'>{name}</div>
    <div className='capsule_count'>{value ? value : 0}</div>
  </div>
  )
}

export default capsules
