import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import { isInputEmailValid,isInputEmpty,isInputPasswordValid } from '../constants/inputValidaation';
// import { register_user } from '../redux/actions/userActions';
import './css/login.css';
import './css/register.css';

import axios from 'axios';
import keys from '../config/keys';


const UserEdit = (props) => {
    const params = useParams();
    const user = props.user;
    const mHistory = useNavigate();
    //   const [submitSuccess, setSubmitSuccess] = useState('');
      
    const [funderList, setFunderList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
      email: '',
      password: '',
      name: '',
      admintype:'',
      funderId:''
    });
    const [requestAuth, setRequestAuth] = useState({
      success: '',
      error: '',
      loading: false,
    });
  
    const [formDataError, setFormDataError] = useState({
      email: '',
      password: '',
      name: '',
      admintype:'',
      funderId:''
    });
  
    const token = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
      if (!user || user === null) {
        return mHistory('/login');
      }
    //   if (!userDetails || userDetails.admintype !== "superadmin") {
    //     console.log({userDetails})
    //     console.log("true userDetails")
    //     return mHistory('/login');
    //   }
      if (!token) return mHistory('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
  
    useEffect(() => {
        getuserDetails()
      getFunderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
  
    const getuserDetails = async ()=>{
        try{
          const {data} = await axios.get(`${keys.SERVER_URL}/userDetails?token=${params?.id}`)
          setFormData(data.data)
        }catch(error){
        }
      }
  
    const getFunderList = async ()=>{
      try{
        const {data} = await axios.get(`${keys.SERVER_URL}/v3/funderGroupList?label=true`)
        setFunderList(data.data)
      }catch(error){
      }
    }
  
    
    const validateForm = () => {
      let hasError = false;
  
      let tempError = {
        email: '',
        password: '',
        name: '',
        admintype:'',
        funderId:''
      };
  
      tempError.email = isInputEmailValid(formData.email);
      if (tempError.email !== '') {
        hasError = true;
      }
      tempError.name = isInputEmpty(formData.name);
      if (tempError.name !== '') {
        hasError = true;
      }
      tempError.admintype = isInputEmpty(formData.admintype);
      if (tempError.admintype !== '') {
        hasError = true;
      }
      tempError.password = isInputPasswordValid(formData.password);
      if (tempError.password !== '') {
        hasError = true;
      }
      tempError.funderId = isInputEmpty(formData.funderId);
      if (tempError.funderId !== '') {
        hasError = true;
      }
  
      setFormDataError({
        ...formDataError,
        ...tempError,
      });
  
      return hasError;
    };
  
    const onSubmit = async () => {
      if (validateForm()) {
        return;
      }
  
      try {
        setRequestAuth({
          loading: true,
          error: '',
          success: '',
        });
  
        // const res = await register(
        //   formData.name,
        //   formData.email,
        //   formData.password,
        //   formData.admintype,
        //   token,
        //   formData.funderId,
  
        // );
        // console.log(res);
        setRequestAuth({
          loading: false,
          error: '',
          success: 'User Created Successfully',
        });
        setTimeout(() => {
          setFormData({
            email: '',
            password: '',
            name: '',
            admintype:'',
            funderId:''
          })
          setRequestAuth({
            success: '',
            error: '',
            loading: false,
          })
        }, 3000);
  
      } catch (error) {
        console.log(error);
        setRequestAuth({
          loading: false,
          error: error?.response?.data?.message,
          success: '',
        });
      }
    };
  
  return (
    <div className="text-center">
      <div className="register_container col-12 col-lg-8">
        <div className="register_head">Edit User</div>
        <form className="d-flex flex-wrap flex-column align-items-center">
          <div className="mb-4 col-lg-4" style={{ textAlign: 'left' }}>
            <label>Enter Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your Name"
              className="form-control p-2  Input"
              value={formData.name}
              onChange={(t) => {
                setFormData({
                  ...formData,
                  name: t.target.value,
                });
              }}
            />
            {formDataError.name && (
              <span className="text-danger">{formDataError.name}</span>
            )}
          </div>
          <div className="mb-4 col-lg-4" style={{ textAlign: 'left' }}>
            <label>Enter Email ID</label>
            <input
              type="email"
              name="Email"
              placeholder="Enter your Email"
              className="form-control p-2  Input"
              value={formData.email}
              onChange={(t) => {
                setFormData({
                  ...formData,
                  email: t.target.value,
                });
              }}
            />
            {formDataError.email && (
              <span className="text-danger">{formDataError.email}</span>
            )}
          </div>
          <div className={'mb-4 col-lg-4  _input_container '} style={{ textAlign: 'left' }}>
          <label className={'_label'}>Select Program</label>
          <select className={ '_input'} style={{ cursor: 'pointer' }} id="year" name="year" value={formData.funderId} onChange={(t) => {setFormData({...formData,funderId: t.target.value})}}>
            <option value="" selected>
              Select
            </option>
            {funderList?.map((item) => (
              <option id={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {formDataError.funderId && (
            <span className="text-danger">{formDataError.funderId}</span>
          )}
        </div>
          {/* <div className="mb-4 col-lg-4 text-left radio_container">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="admin"
                id="admin"
                checked={formData.admintype === 'admin' ? true : false }
                value={formData.admintype === 'admin' ? true : false }
                onChange={e=>{
                  if(e.target.checked) setFormData({...formData,admintype:'admin'})
                }}
              />
              <label class="form-check-label" for="flexRadioDefault1">
              Admin 
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="standard_user"
                id="standard_user"
                checked={formData.admintype === 'standard_user' ? true : false }
                value={formData.admintype === 'standard_user' ? true : false }
                onChange={e=>{
                  if(e.target.checked) setFormData({...formData,admintype:'standard_user'})
                }}
              />
              <label class="form-check-label" for="flexRadioDefault2">
              Standard User
              </label>
            </div>
          </div> */}
          <div className='col-lg-4 mb-2 text-left' style={{textAlign:"left"}}>
            <label>Enter Password</label>
          </div>
          <div className={`mb-4 col-lg-4 Container`}>
            <div>
              <input
                type={showPassword ? 'text' : 'password'}
                name={'password'}
                id={'password'}
                className="Input"
                value={formData.password}
                placeholder={'Please enter your password'}
                onChange={(e) => {
                  // setShowBtn(true);
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  });
                }}
              />
              <img
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                src={
                  showPassword
                    ? '/assets/icons/iconPasswordShow.svg'
                    : '/assets/icons/iconPasswordHide.svg'
                }
                alt={showPassword ? 'Show Password' : 'Hide Password'}
              />
            </div>
          </div>
          { formDataError.admintype && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              Plese Select one filed
            </div>
          )}
          {(formDataError.email || formDataError.password) && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              Invalid password or Email
            </div>
          )}

          {requestAuth.loading && (
            <div className="rounded mt-1 mb-1 p-1 border border-info text-center text-info">
              Loading...
            </div>
          )}
          {requestAuth.success && (
            <div className="rounded mt-1 mb-1 p-1 border border-success text-center text-success">
              {requestAuth.success}
            </div>
          )}
          {requestAuth.error && (
            <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
              {requestAuth.error}
            </div>
          )}

          <div className={'__btnSubmit_container col-lg-4 mb-2'}>
            {formData.password !== '' &&
            formData.name !== '' &&
            formData.email !== '' ? (
                <div>
              <button className={`login_sectionSubmit_button  mb-3 bg-success`} type="button" onClick={() => onSubmit()} >
                Submit
              </button>
              <button className={`login_sectionSubmit_button bg-warning`} type="button" onClick={()=> mHistory(-1)}  >
                Cancel
              </button>
                  </div>
            ) : (
                <div>
              <button className={`login_sectionSubmit_disabledButton bg-warning`}  type="button" onClick={()=> mHistory(-1)}>
                Cancel
              </button>
              <button className={`login_sectionSubmit_disabledButton mb-3 bg-success`} style={{display:"none"}} disabled type="button" onClick={() => onSubmit()} >
                Submit
              </button>
                  </div>
            )}
          </div>
          {/* <Link to="/login">Already Have An Account</Link> */}
        </form>
      </div>
    </div>
  )
}

const mapStateStore = (stateStore) => {
    return {
      user: stateStore.userState.user,
    };
  };

export default connect(mapStateStore,{})(UserEdit)
