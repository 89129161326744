import React, { useEffect, useState } from 'react'
import axios from 'axios';
import configKeys from '../../config/keys';

import "./css/awards.css"
import { titleCase } from "title-case";
import { useLocation, useParams } from 'react-router-dom';
const TopAward = ({name, showHeader}) => {
    const params = useParams();
    const location  = useLocation()
    const startMonthYear = new URLSearchParams(location.search).get("startMonthYear")
    const curMonthYear = new URLSearchParams(location.search).get("curMonthYear")
    const awardsType = {
        school:"School",
        teacher:"Teacher"
    }
    const [SectionHeading,setSectionHeading] = useState('')
    const [TableHeading,setTableHeading] = useState('')
    useEffect(() => {
        getTopAwards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const [TopAwards, setTopAwards] = useState('')
    const [Loading, setLoading] = useState({
        loading: false,
        success: '',
        error: '',
      });
    const token = JSON.parse(localStorage.getItem('user'));

    const getTopAwards = async () => {
        try {
          setLoading({
            loading: true,
            success: '',
            error: '',
          });
          if(awardsType.school === name){
              const { data } = await axios(`${configKeys.SERVER_URL}/topSchools/${params.id}?startMonthYear=${startMonthYear}&curMonthYear=${curMonthYear}`,{headers: {"Authorization":token.token}});
              setTopAwards(data.data);
              setSectionHeading('Top '+data.data.count+' Schools')
              setTableHeading('School Name')
          }else{
              const { data } = await axios(`${configKeys.SERVER_URL}/topTeachers/${params.id}?startMonthYear=${startMonthYear}&curMonthYear=${curMonthYear}`,{headers: {"Authorization":token.token}});
              setTopAwards(data.data);
              setSectionHeading('Top '+data.data.count+' Teachers')
              setTableHeading('Teacher Name')
          }
          setLoading({
            loading: false,
            success: '',
            error: '',
          });
        } catch (error) {
          setLoading({
            loading: false,
            success: '',
            error: error.message,
          });
        }
      };

  return (
    <div className='container'>
      {showHeader && (
      <div className='section_1'>
        <div className='d-flex justify-content-between flex-column'>
          <div className='section_1_head'>
            Performance Tracking
          </div>
        </div>
        <div className='' >
          <div className='d-flex justify-content-end'>
            <div className='section_1_year'>
            { /* (TopAwards?.program) */ }
            </div>
          </div>
        </div>
      </div>
      )}
      <div className='head_2_container' >
        <div className='heading_2' style={{backgroundImage: 'url(/assets/dashbord/header-strip.png)', backgroundSize: 'cover'}}>
            {SectionHeading}
        </div>
      </div>
      {Loading.loading && <h1>...Loading</h1> }
      {!Loading.loading && TopAwards && (
        <div className='head_2_container' >
        <table cellPadding={'0px'} cellSpacing={'0px'} border={0}>
          <tr>
            <td width="50%" style={{verticalAlign: 'top'}}>     
              <table className='awardlist' style={{border:'0px'}}>
                <tr>
                  <th></th>
                  <th></th>
                  <th>S.N.</th>
                  <th>{TableHeading}</th>
                  <th>District</th>
                </tr>
                {TopAwards?.list1?.map((item,index) => (
                  <tr>
                    <td style={{verticalAlign: 'top'}}></td>
                    <td style={{verticalAlign: 'top'}}></td>
                    <td style={{verticalAlign: 'top'}}>{index+1}</td>
                    <td style={{verticalAlign: 'top'}}>{(item.name) ? titleCase(item.name.toLowerCase()) : item.diseCode}</td>
                    <td style={{verticalAlign: 'top'}}>{(item.district_name) ? titleCase(item.district_name.toLowerCase()) : ((item.districtName) ? titleCase(item.districtName.toLowerCase()) : '')}</td>
                  </tr>
                ))}
                <tr>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                </tr>
              </table>
            </td>
            <td width="50%" style={{verticalAlign: 'top'}}>     
              <table className='awardlist' style={{border:'0px'}}>
                <tr>
                  <th></th>
                  <th></th>
                  <th>S.N.</th>
                  <th>{TableHeading}</th>
                  <th>District</th>
                </tr>
                {TopAwards?.list2?.map((item,index) => (
                  <tr>
                    <td style={{verticalAlign: 'top'}}></td>
                    <td style={{verticalAlign: 'top'}}></td>
                    <td style={{verticalAlign: 'top'}}>{index+51}</td>
                    <td style={{verticalAlign: 'top'}}>{(item.name) ? titleCase(item.name.toLowerCase()) : item.diseCode}</td>
                    <td style={{verticalAlign: 'top'}}>{(item.district_name) ? titleCase(item.district_name.toLowerCase()) : ((item.districtName) ? titleCase(item.districtName.toLowerCase()) : '')}</td>
                  </tr>
                ))}
                <tr>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                  <td style={{verticalAlign: 'top'}}>&nbsp;</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        </div>
      )}

    </div>
  )
}

export default TopAward
