import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configKeys from '../config/keys';
import inputValidation from '../constants/inputValidaation';
import {  Link, useNavigate } from 'react-router-dom';
import './css/schoolImpacted.css';

const StaticDataByUser = (props) => {
  const mHistory = useNavigate();
  const navigate = useNavigate();

  const month = { '1': 'January, February, March' ,
  '4': 'April, May, June' ,
  '7': 'July, August, September' ,
  '10': 'October, November, December' };
  
  const [funderMonths, setFunderMonths] = useState({})
  const [quarters, setQuarters] = useState('')
  //const [isYearDisabled, setIsYearDisabled] = useState(false);
  //const [isQuarterDisabled, setIsQuarterDisabled] = useState(false);
  const [Loading, setLoading] = useState({
    loading: false,
    success: '',
    error: '',
  });
  const [schoolList, setschoolList] = useState([]);
  const [requestAuth, setRequestAuth] = useState({
    success: '',
    error: '',
    loading: false,
  });
  const [funderList, setFunderList] = useState([])
  // const [districtList, setDistrictList] = useState([]);
  // const [stateList, setstateList] = useState([]);
  const [formData, setformData] = useState({
    year: '2023',
    quarter: '',
    funderId: '', 
    yearly_budget:'',
    spark_count: '',
    districts_covered: '',
    blocks_covered: '',
    stv_distributed: '',
    led_distributed: '',
    kits_distributed: '',
    teachers_trained: '',
    mt_trained: '',
    total_schools_in_state: '',
    schools_covered: '',
    total_students_in_state: '',
    students_covered: '',
    girl_child_percent: '',
    program_expenses_ytd: '',
    program_expenses_qtd: ''
  });
  const [fromDataError, setFromDataError] = useState({
    year: '',
    quarter: '',
    funderId: ''
  });
  // const [userList, setuserList] = useState([])
  // const [selectedUsers, setselectedUsers] = useState('')

  useEffect(() => {
    getStaticDataByFunder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.funderId]);

  const getStaticDataByFunder = async () => {
    try {
      setLoading({
        loading: true,
        success: '',
        error: '',
      });

      const { data } = await axios(`${configKeys.SERVER_URL}/StaticDataByFunder?year=${formData.year}&funderId=${formData.funderId}`);
      setschoolList(data.data);

      setformData({
        ...formData,
        yearly_budget: data.data[data.data.length-1].yearly_budget,
        total_schools_in_state: data.data[data.data.length-1].total_schools_in_state,
        schools_covered: data.data[data.data.length-1].schools_covered,
        total_students_in_state: data.data[data.data.length-1].total_students_in_state,
        students_covered: data.data[data.data.length-1].students_covered
      });

      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    } catch (error) {
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  };

  const token = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    // if (!userToken || userToken === null) {
    //   console.log("true userToken",userToken)
    //   return mHistory('/login');
    // }
    // if (!userDetails || userDetails.admintype !== "superadmin") {
    //   console.log({userDetails})
    //   return mHistory('/login');
    // }
    if (!token) return mHistory('/login');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (!userDetails || userDetails.admintype !== "superadmin") {
    //   return mHistory('/');
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // getStateList();
    getFunderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getMonths = async(selectedFunder) => {
    let startMonth = parseInt(selectedFunder['programStartMonth'])
    let startYear = selectedFunder['programStartYear']
    let endMonth = parseInt(selectedFunder['programEndMonth'])
    let endYear = selectedFunder['programEndYear']
    const date = new Date();
    const curYear = date.getFullYear()
    const curMonth = date.getMonth()
    let tempmonths = []
    let quarter = 1
    if (startYear === endYear) {
      for (let i=startMonth;i<=endMonth;i=i+3) {
        if (parseInt(startYear) < parseInt(curYear)) {
          tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter})
        } else if (parseInt(startYear) === parseInt(curYear) && i <= curMonth) {
          tempmonths.push({name:month[i.toString()]+' - '+startYear,value:quarter})
        }
        quarter = quarter + 1
      }
    } else {
      let tempYear = parseInt(startYear)
      while (tempYear <= parseInt(endYear)) 
      { 
        let tempStartMonth = 1
        let tempEndMonth = 12
        if (tempYear === parseInt(startYear)) {
          tempStartMonth = startMonth
        }
        if (tempYear === parseInt(endYear)) {
          tempEndMonth = endMonth
        }
        for (let i=tempStartMonth;i<=tempEndMonth;i=i+3) {
          if ((parseInt(tempYear) === parseInt(curYear) && i <= curMonth) || (parseInt(tempYear) < parseInt(curYear))) {
            tempmonths.push({name:month[i.toString()]+' - '+tempYear,value:quarter})
            quarter = quarter + 1
          }
        }
        tempYear = tempYear + 1
      }
    }
    funderMonths[selectedFunder['_id']] = tempmonths
    setFunderMonths(funderMonths)
  }
  // useEffect(() => {
  //   if (formData.state_id) {
  //     getDistrictList(formData.state_id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formData.state_id]);

  // const getDistrictList = async (stateId) => {
  //   try {
  //     if (checkIfError()) {
  //       return;
  //     }
  //     setLoading({
  //       loading: true,
  //       success: '',
  //       error: '',
  //     });
  //     const { data } = await axios(
  //       `${configKeys.SERVER_URL}/getDistrict?stateId=${stateId}`
  //     );
  //     setDistrictList(data.data);
  //     console.log(data.data);

  //     setLoading({
  //       loading: false,
  //       success: '',
  //       error: '',
  //     });
  //   } catch (error) {
  //     setLoading({
  //       loading: false,
  //       success: '',
  //       error: error.message,
  //     });
  //   }
  // };

  // const getStateList = async () => {
  //   try {
  //     setLoading({
  //       loading: true,
  //       success: '',
  //       error: '',
  //     });

  //     const { data } = await axios(`${configKeys.SERVER_URL}/getStates`);
  //     setstateList(data.data);
  //     console.log(data.data);

  //     setLoading({
  //       loading: false,
  //       success: '',
  //       error: '',
  //     });
  //   } catch (error) {
  //     setLoading({
  //       loading: false,
  //       success: '',
  //       error: error.message,
  //     });
  //   }
  // };

  const getFunderList = async ()=>{
    try{
      setLoading({
        loading: true,
        success: '',
        error: '',
      });
      const {data} = await axios.get(`${configKeys.SERVER_URL}/v3/funderGroupList?label=true`)
      setFunderList(data.data)
      data.data?.map((item) => (
        getMonths(item)
      ))
      setLoading({
        loading: false,
        success: '',
        error: '',
      });
    }catch(error){
      setLoading({
        loading: false,
        success: '',
        error: error.message,
      });
    }
  }


  // const checkIfError = () => {
  //   let hasError = false; // False means the form has no error and it can be submitted
  //   let tempError = {
  //     state_id: '',
  //   };
  //   if (inputValidation.isInputHasLength(formData.state_id) !== '') {
  //     tempError.state_id = inputValidation.isInputHasLength(formData.state_id);
  //     if (formData.allState) {
  //       hasError = false;
  //       tempError.state_id = '';
  //     } else hasError = true;
  //   }
  //   setFromDataError({
  //     ...fromDataError,
  //     ...tempError,
  //   });
  //   console.log({ hasError });
  //   return hasError;
  // };

  const validateForm = () => {
    let hasError = false;

    let tempError = {
      year: '',
      quarter: '',
      state_id: '',
      state_name: '',
      district_id: '',
      district_name: '',
      schoolCount: '',
      funderId: '',
    };

    tempError.funderId = inputValidation.isInputEmpty(formData.funderId);
    if (tempError.funderId !== '') {
      hasError = true;
    }
    // tempError.district_id = inputValidation.isInputEmpty(formData.district_id);
    // if (tempError.district_id !== '') {
    //   hasError = true;
    // }
    tempError.quarter = inputValidation.isInputEmpty(formData.quarter);
    if (tempError.quarter !== '') {
      hasError = true;
    }

    setFromDataError({
      ...fromDataError,
      ...tempError,
    });

    return hasError;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      return;
    }

    try {
      setRequestAuth({
        loading: true,
        error: '',
        success: '',
      });
      const { data } = await axios.post(`${configKeys.SERVER_URL}/createStaticData`,{ ...formData }, { headers: { "Authorization": token.token } });

      setRequestAuth({
        loading: false,
        error: '',
        success: data.message,
      });
      setTimeout(() => {
        setformData({
          year: '',
          quarter: '',
          funderId:'',
          spark_count: '',
          yearly_budget: '',
          led_distributed: '',
          stv_distributed: '',
          kits_distributed: '',
          mt_trained: '',
          teachers_trained: '',
          total_schools_in_state: '',
          schools_covered: '',
          districts_covered: '',
          blocks_covered: '',
          total_students_in_state: '',
          students_covered: '',
          girl_child_percent: '',
          program_expenses_ytd: '',
          program_expenses_qtd: ''
        });
        setRequestAuth({
          success: '',
          error: '',
          loading: false,
        });
      }, 3000);
    } catch (error) {
      console.log(error);
      setRequestAuth({
        loading: false,
        error: error?.response?.data?.message,
        success: '',
      });
    }
  };

  // const  handleChange = (selected) => {
  //   setselectedUsers(selected)
  //   console.log({selectedUsers})
  // };

  return (
    <div className="container">
              <div className='d-flex justify-content-between' >
        <div className="mt-5 mb-4 schoolHead">Create Static Data</div>
  
        <Link  className='mt-5 mb-5 btn btn-secondary '  to="/funderGroup">Create Program</Link>
        </div>
      <div className="d-flex flex-wrap">
        <div className={'_input_container flex_per_row_for_filters'}>
          <label className={'_label'}>Select Program</label>
          <select
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="user"
            name="user"
            value={formData.funderId}
            onChange={(t) => {
              setformData({
                ...formData,
                funderId: t.target.value,
                quarter: '',
              });
              setQuarters(funderMonths[t.target.value])
            }}
          >
            <option value="" selected>
              Select
            </option>
            {!Loading.loading && funderList?.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </select>
          {fromDataError.funderId && (
            <span className="text-danger">{fromDataError.funderId}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row_for_filters'} style={{display:'none'}}>
          <label className={'_label'}>Select Year</label>
          <select
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="year"
            name="year"
            value={formData.year}
            onChange={(t) => {
              setformData({
                ...formData,
                year: t.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              Select
            </option>
            <option value="2023">2023-2024</option>
            <option value="2024">2024-2025</option>
          </select>
          {fromDataError.year && (
            <span className="text-danger">{fromDataError.year}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row_for_filters'}>
          <label className={'_label'}>Select Quarter </label>
          <select
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="month"
            name="month"
            value={formData.quarter}
            onChange={(t) => {
              setformData({
                ...formData,
                quarter: t.target.value,
              });
              if (t.target.value === "0") {
                //setIsQuarterDisabled(true)
                //setIsYearDisabled(false)
              } else {
                //setIsQuarterDisabled(false)
                //setIsYearDisabled(true)
              }
            }}
          >
            <option value="" selected>
              Select
            </option>
            {quarters && quarters.map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
          {fromDataError.quarter && (
            <span className="text-danger">{fromDataError.quarter}</span>
          )}
        </div>
      </div>

      <div className='d-flex flex-wrap mt-5'>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Yearly Budget (ex: 100000000)</label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.yearly_budget}
            placeholder={'Enter Yearly Budget'}
            onChange={(t) => {
              setformData({
                ...formData,
                yearly_budget: t.target.value,
              });
            }}
          />

          {fromDataError.yearly_budget && (
            <span className="text-danger">{fromDataError.yearly_budget}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Program Expenses YTD (in Lakhs) (ex: 12.56)</label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.program_expenses_ytd}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                program_expenses_ytd: t.target.value,
              });
            }}
          />

          {fromDataError.program_expenses_ytd && (
            <span className="text-danger">{fromDataError.program_expenses_ytd}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Program Expenses QTD (in Lakhs) (ex: 12.56)</label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.program_expenses_qtd}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                program_expenses_qtd: t.target.value,
              });
            }}
          />

          {fromDataError.program_expenses_qtd && (
            <span className="text-danger">{fromDataError.program_expenses_qtd}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Kits Distributed </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.kits_distributed}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                kits_distributed: t.target.value,
              });
            }}
          />

          {fromDataError.kits_distributed && (
            <span className="text-danger">{fromDataError.kits_distributed}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Sampark TV Distributed </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.stv_distributed}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                stv_distributed: t.target.value,
              });
            }}
          />

          {fromDataError.stv_distributed && (
            <span className="text-danger">{fromDataError.stv_distributed}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>LED Distributed </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.led_distributed}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                led_distributed: t.target.value,
              });
            }}
          />

          {fromDataError.led_distributed && (
            <span className="text-danger">{fromDataError.led_distributed}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. Of Sparks </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.spark_count}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                spark_count: t.target.value,
              });
            }}
          />

          {fromDataError.spark_count && (
            <span className="text-danger">{fromDataError.spark_count}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Teachers Trained </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.teachers_trained}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                teachers_trained: t.target.value,
              });
            }}
          />

          {fromDataError.teachers_trained && (
            <span className="text-danger">{fromDataError.teachers_trained}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>MT Trained </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.mt_trained}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                mt_trained: t.target.value,
              });
            }}
          />

          {fromDataError.mt_trained && (
            <span className="text-danger">{fromDataError.mt_trained}</span>
          )}
        </div>
        

        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. Of Schools In State </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.total_schools_in_state}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                total_schools_in_state: t.target.value,
              });
            }}
          />

          {fromDataError.total_schools_in_state && (
            <span className="text-danger">{fromDataError.total_schools_in_state}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. Of Students In State </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.total_students_in_state}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                total_students_in_state: t.target.value,
              });
            }}
          />

          {fromDataError.total_students_in_state && (
            <span className="text-danger">{fromDataError.total_students_in_state}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Percentage of Girl Child </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.girl_child_percent}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                girl_child_percent: t.target.value,
              });
            }}
          />

          {fromDataError.girl_child_percent && (
            <span className="text-danger">{fromDataError.girl_child_percent}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Districts Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.districts_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                districts_covered: t.target.value,
              });
            }}
          />

          {fromDataError.districts_covered && (
            <span className="text-danger">{fromDataError.districts_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>Blocks Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.blocks_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                blocks_covered: t.target.value,
              });
            }}
          />

          {fromDataError.blocks_covered && (
            <span className="text-danger">{fromDataError.blocks_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. of Schools Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.schools_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                schools_covered: t.target.value,
              });
            }}
          />

          {fromDataError.schools_covered && (
            <span className="text-danger">{fromDataError.schools_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
          <label className={'_label'}>No. of Students Covered </label>
          <input
            className={'_input'}
            style={{ cursor: 'pointer' }}
            id="count"
            name="count"
            type={'Number'}
            value={formData.students_covered}
            placeholder={'Enter Count'}
            onChange={(t) => {
              setformData({
                ...formData,
                students_covered: t.target.value,
              });
            }}
          />

          {fromDataError.students_covered && (
            <span className="text-danger">{fromDataError.students_covered}</span>
          )}
        </div>
        <div className={'_input_container flex_per_row'}>
        </div>
        <div className={'_input_container flex_per_row'}>
        </div>
      </div>
     
      {requestAuth.loading && (
        <div className="rounded mt-1 mb-1 p-1 border border-info text-center text-info">
          Loading...
        </div>
      )}
      {requestAuth.success && (
        <div className="rounded mt-1 mb-1 p-1 border border-success text-center text-success">
          {requestAuth.success}
        </div>
      )}
      {requestAuth.error && (
        <div className="rounded mt-1 mb-1 p-1 border border-danger text-center text-danger">
          {requestAuth.error}
        </div>
      )}
      <div className="text-left">
        <button className="btn btnSchool bg-success text-white" onClick={() => onSubmit()}>
          Submit
        </button>
        <button onClick={()=> navigate(-1)} className='btn btnSchool bg-danger text-white' to="/">Cancel</button>
      </div>
    </div>
  );
}


export default StaticDataByUser
