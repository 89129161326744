import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import configKeys from '../config/keys';
// import inputValidation from '../constants/inputValidaation';

const FunderList = (props) => {    
  const [updateMessage, setUpdateMessage] = useState('');
    const [funderList, setFunderList] = useState([]);
    const [Loading, setLoading] = useState({
      loading: false,
      success: '',
      error: '',
    });

    useEffect(() => {
      // if(userDetails?.admintype !== "superadmin"){
      //   return navigate('/');
      // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
      getFunderList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const getFunderList = async () => {  
      try {
        setLoading({
          loading: true,
          success: '',
          error: '',
        });
        const { data } = await axios(`${configKeys.SERVER_URL}/v3/funderGroupList`);
        setFunderList(data.data);
  
        setLoading({
          loading: false,
          success: '',
          error: '',
        });
      } catch (error) {
        setLoading({
          loading: false,
          success: '',
          error: error.message,
        });
      }
    };

    const updateFunderList = async () => {  
      try {
        setLoading({
          loading: true,
          success: '',
          error: '',
        });
        const { data } = await axios(`${configKeys.SERVER_URL}/v3/updateFunderList`);
        setFunderList(data.data);
        if (data.message === "success") {
          setUpdateMessage("Programs Updated")
        }
  
        setLoading({
          loading: false,
          success: '',
          error: '',
        });
      } catch (error) {
        setLoading({
          loading: false,
          success: '',
          error: error.message,
        });
      }
    };
    return (
        <div className="container">
        <div className='d-flex justify-content-between' >
        <div className="mt-5 mb-4 schoolHead">Programs Created By Admin</div>
  
        <Link  className='mt-5 mb-5 btn btn-secondary'  onClick={updateFunderList}>Update Programs From Inventory</Link>
        </div>
        <div>{updateMessage}</div>
        <table className="withBorder">
          <tr>
            <th>Program Name</th>
            <th>Type</th>
            <th>Duration</th>
            <th>State </th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          {funderList?.map((item) => (
            <tr>
              <td><Link to={`/funderGroupEdit/${item._id}`} >{item.name}</Link></td>
              <td>{(item.programType)}</td>
              <td>{item.duration}</td>
              <td>{(item?.states && item?.states[0]) ? item?.states[0]['label'] : '-'}</td>
              <td>{item.status}</td>
              <td><Link to={`/staticDataList/${item._id}`} >Static Data</Link></td>
            </tr>
          ))}
        </table>
          {funderList?.length === 0 && <dir>Empty School List</dir>}
        
          {Loading.loading && <h1>Loading..... </h1>}
      </div>
    );
}

export default FunderList
